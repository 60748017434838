import {Box, Grid, Typography} from '@mui/material';
import React from 'react';
import { Theme } from '@mui/material/styles';
import {makeStyles} from '@theme/makeStyles';
import { useAppContext } from '../../Context/AppContext';
import { useTranslation } from 'react-i18next';
import NewPublications from '@model/NewPublications';
import UserAvatar from '@components/User/UserAvatar';
import UsernameBadge from '@components/Utils/UsernameBadge';
import { ROOT_ROUTES } from '../../Constants/routes';
import Link from 'next/link';
import { SPACING_FACTOR } from '@theme/theme';

const useStyles = makeStyles()((theme: Theme) => ({
    text: {
        cursor: 'pointer'
    },
    box: {
        position: 'relative'
    },
    avatar: {
        width: '52px',
        height: '52px',
        objectFit: 'contain',
        marginLeft: 0
    },
    wrapper: {
        border: '1px solid ' + theme.palette.divider,
        borderRadius: '10px',
        padding: '10px',
        cursor: 'pointer'
    }
}));

interface NewsUserProps {
    style?: React.CSSProperties;
    data?: NewPublications[];
}

export const calculateHeight = (nbUsers: number): number => {
    const titleDescription = 55;
    const margins = SPACING_FACTOR*2;
    const user = 74;
    const divider = SPACING_FACTOR*1.5;

    if (nbUsers === 0) {
        return 0;
    }

    return titleDescription + margins + user*nbUsers + divider*nbUsers;
};

const NewUsersPublications = (props: NewsUserProps): JSX.Element => {
    const { classes } = useStyles();
    const { style, data } = props;
    const {t} = useTranslation('common');
    const {controllers} = useAppContext();
    const [showMore, setShowMore] = React.useState<boolean>(false);

    const [newPublications, setNewPublications] = React.useState<NewPublications[]>(data);

    React.useEffect(() => {
        if (!newPublications) {
            controllers.userController.getNewPublications({perPage: 6, page: 1}).then(pubs => {
                setNewPublications(pubs);
            });
        }
    }, []);

    if (!newPublications) {
        return <></>;
    }

    const usersToShow = showMore ? 6 : 3;

    return (
        <Box style={style} pb={1}>
            <div>
                <Typography variant='h1' fontWeight={700} fontSize={'24px'}>{t('NEWS_USERS')}</Typography>

                {newPublications.length > 0 && <Box mt={1}><Typography variant={'h3'} color={'textPrimary'} fontSize={'16px'}>{t('NEWS_USERS_DESCRIPTION')}</Typography></Box>}
            </div>

            <Box mt={newPublications.length > 0 ? 1 : 0} mb={newPublications.length > 0 ? 1 : 0}>
                {newPublications.length > 0 && newPublications.slice(0, usersToShow).map((newPublication) =>
                    <Link key={newPublication.user.id} href={ROOT_ROUTES.USER(newPublication.user.username)}>
                        <Grid container alignItems={'center'} wrap={'nowrap'} className={classes.wrapper} mb={'10px'}>
                            <Grid item container alignItems={'center'} wrap={'nowrap'}>
                                <UserAvatar avatarUrl={[newPublication.user.avatarUrl]} className={classes.avatar} username={[newPublication.user.username]} />

                                <Box ml={'10px'} className={classes.box}>
                                    <Box display={'flex'}>
                                        <UsernameBadge style={{width: 'fit-content'}} username={newPublication.user.username} displayName={newPublication.user.displayName} isInfluencer={true} />
                                    </Box>

                                    <Typography color={'textPrimary'} fontSize={'15px'} lineHeight={'22px'}>
                                        {`${t('ADDED')} ${newPublication.photos > 0 ? t('ADDED_PHOTOS', {count: newPublication.photos, photos: newPublication.photos}) : ''}${(newPublication.photos > 0 && newPublication.videos > 0) ? ' ' + t('AND') + ' ' : ''}${newPublication.videos > 0 ? t('ADDED_VIDEOS', {count: newPublication.videos, videos: newPublication.videos}) : ''}`}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Link>
                )}
            </Box>

            {newPublications.length > 3 && <Typography variant={'h3'} color={'primary'} style={{cursor: 'pointer'}} aria-label={'show more/less'} onClick={() => setShowMore(!showMore)}><strong>{showMore ? t('SHOW_LESS') : t('SHOW_MORE')}</strong></Typography>}

            {newPublications.length === 0 && <Box mt={1}><Typography variant={'h3'} color={'textPrimary'} fontSize={'16px'}>
                {t('NEWS_USERS_UP_TO_DATE')}
            </Typography></Box>}
        </Box>
    );
};

export default NewUsersPublications;
import {Skeleton, Theme} from '@mui/material';
import React from 'react';
import {makeStyles} from '@theme/makeStyles';
import {avatarSizeLarge, avatarSizeSmall, UserCardSize} from '@components/User/UserCard';
import {SPACING_FACTOR} from '@theme/theme';

const useStyles = makeStyles<{size: UserCardSize, mb: number}>()((theme: Theme, {size, mb}) => ({
    avatarWrapper: {
        objectFit: 'contain',
        marginLeft: 0,
        position: 'absolute',
        left: '10px',
        backgroundColor: theme.palette.background.paper,
        border: '4px solid ' + theme.palette.background.paper,
        bottom: `${-(size === 'small' ? avatarSizeSmall : avatarSizeLarge)/2}px`,
        borderRadius: '50%'
    },
    avatar: {
        width: `${size === 'small' ? avatarSizeSmall : avatarSizeLarge}px`,
        height: `${size === 'small' ? avatarSizeSmall : avatarSizeLarge}px`,
    },
    text: {
        position: 'absolute',
        left: `${(size === 'small' ? avatarSizeSmall : avatarSizeLarge) + 20}px`,
        width: '60px'
    },
    container: {
        marginBottom: `${((size === 'small' ? avatarSizeSmall : avatarSizeLarge)/2) + mb*SPACING_FACTOR}px`,
        position: 'relative'
    }
}));

const UserCardSkeleton = (props: {size?: UserCardSize, mb?: number}): JSX.Element => {
    const {size = 'small', mb = 0} = props;
    const {classes} = useStyles({size, mb});
    
    return (
        <div className={classes.container}>
            <Skeleton height={70} style={{transform: 'none'}} />

            <div className={classes.avatarWrapper}>
                <Skeleton variant={'circular'} className={classes.avatar}/>
            </div>

            <Skeleton variant={'text'} className={classes.text} />
        </div>
    );
};

export default UserCardSkeleton;
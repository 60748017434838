import React, {useMemo, useRef} from 'react';
import {useAppContext} from '@context/AppContext';
import AbstractPage, {PageConfig} from './AbstractPage';
import PostManager from '../src/Provider/Controller/post/PostManager';
import dynamic from 'next/dynamic';
import EmailValidationBanner from '../src/Components/Layout/EmailValidationBanner';
import {retainableCacheKeys, useRetainableDataPagination} from '@provider/Pagination';
import {useEventBus} from '../src/EventBus/EventBus';
import { Box, Grid } from '@mui/material';
import SuggestedUsers from '../src/Components/User/SuggestedUsers';
const ExploreFeed = dynamic(() => import('../src/Components/Post/ExploreFeed').catch(dynamicGuard));
import {useUpdater} from '@context/UpdaterContext';
import { GetStaticProps } from 'next';
import { PageProps } from './_app';
import { ROOT_ROUTES } from '../src/Constants/routes';
import dynamicGuard from '../src/Components/Utils/dynamicGuard';
import { useThemeContext } from '@theme/ThemeContext';
import ScrollButton from '@components/Utils/ScrollButton';
import NewUsersPublications from '@components/User/NewUsersPublications';
import { HEADER_HEIGHT_DESKTOP } from '@theme/theme';
import { makeStyles } from '@theme/makeStyles';
import VirtualizedPostFeed, {VirtualizedRow} from '@components/VirtualizedPostFeed';
import PostSkeleton from '@components/NewPost/PostSkeleton';

const useStyles = makeStyles()(() => ({
    sideBar: {
        position: 'sticky',
        top: HEADER_HEIGHT_DESKTOP + 8
    }
}));

const PageContent = (): JSX.Element => {
    const {controllers, authUser, cache, router} = useAppContext();
    const {isDesktop} = useThemeContext();
    const [update, updater] = useUpdater();
    const {classes} = useStyles();

    const [virtualizationUpdate, virtualizationUpdater] = useUpdater();

    const pagination = useRetainableDataPagination<PostManager | VirtualizedRow>('timeline', pag => controllers.explorePostController.getTimelinePosts(pag), false, [update]);

    const {data, contentLoaded, reset, loading} = pagination;

    const showSuggested = React.useMemo(() => data.length < 15 && data.every(post => !(post instanceof PostManager) || (authUser && post.data.userId === authUser.data.id)), [data, authUser]);
    const showNewPublications = authUser && authUser.visitorStatus.followingCount > 0;

    const postsRef = useRef<HTMLDivElement>();

    const [suggestedUpdater, reloadSuggested] = useUpdater();

    const suggestedUsers: VirtualizedRow = useMemo(() => {
        if (isDesktop) {
            return {
                renderer: () => <div/>,
                height: () => 0
            };
        }

        return {
            renderer: () => <Box pr={1} pl={1}><SuggestedUsers key={suggestedUpdater} style={{height: 300}} suggestionsToShow={2} /></Box>,
            height: () => 300
        };
    }, [isDesktop]);

    // ! Important: Insert suggested users
    if (contentLoaded && !isDesktop && data.length > 5) {
        pagination.data = [...pagination.data.slice(0, 2), ...pagination.data.slice(2, 5), suggestedUsers, ...pagination.data.slice(5)];
    }

    const reloadPosts = () => {
        // Clear cached posts and force reload
        retainableCacheKeys('timeline').map(key => cache.delete(key));

        pagination.refresh(true, 1).then(() => {
            updater();
            virtualizationUpdater();
        });
    };

    const resetPosts = () => {
        if (contentLoaded) {
            // Clear cached posts
            retainableCacheKeys('timeline').map(key => cache.delete(key));
            pagination.refresh(true, 1).then(() => {
                updater();
                reset();
            });
        }
    };

    useEventBus([
        { // Reload timeline when post created | post edited | followed | unfollowed | blocked | unblocked | repost
            id: 'timeline-reload-posts',
            callback: reloadPosts,
            events: ['sugarfans.post.created', 'sugarfans.post.deleted', 'sugarfans.post.edited', 'sugarfans.user.blocked','sugarfans.user.followed','sugarfans.user.unfollowed','sugarfans.user.subscribed', 'post_updated', 'sugarfans.repost.created', 'sugarfans.user.posts_hidden']
        },
        { // Reset timeline when user logs in / registers in case timeline is cached
            id: 'timeline-reset-posts',
            callback: resetPosts,
            events: ['sugarfans.user.logged', 'sugarfans.user.registered']
        }
    ]);

    React.useEffect(() => {
        const handler = e => e.preventDefault();
        document.addEventListener('gesturestart', handler);
        document.addEventListener('gesturechange', handler);

        return () => {
            document.removeEventListener('gesturestart', handler);
            document.removeEventListener('gesturechange', handler);
        };
    }, []);

    if (!authUser) { return <></>; }

    return (
        <Grid container justifyContent={'space-between'}>
            <Grid item container direction='column' lg={8}>
                <ScrollButton pathname={router.pathname} />

                {!authUser.data.validEmail && contentLoaded && (
                    <EmailValidationBanner />
                )}


                <Box mt={1} ref={postsRef}>
                    {!isDesktop && !contentLoaded ? (
                        <div>
                            <Box mb={2}>
                                <PostSkeleton/>
                            </Box>
                            <Box mb={2}>
                                <PostSkeleton/>
                            </Box>
                            <Box>
                                <PostSkeleton/>
                            </Box>
                        </div>
                    ) : (
                        <VirtualizedPostFeed
                            wrapperRef={postsRef}
                            pagination={pagination}
                            update={virtualizationUpdate}
                            saveScrollState
                            feedKey={'timeline'}
                            postOpts={{showHidePostsOption: true}}
                        />
                    )}
                </Box>

                {showSuggested && !loading && (
                    <ExploreFeed key={`suggest-${update}`} onGenderAsked={() => reloadSuggested()} />
                )}
            </Grid>
            <Grid item lg={3.5}>
                <Box className={classes.sideBar}>
                    {isDesktop && showNewPublications && (
                        <Box pb={2} sx={{display: {xs: 'none', lg: 'block'}}} >
                            <NewUsersPublications style={{width: '100%'}} />
                        </Box>
                    )}

                    {isDesktop && (
                        <Box sx={{display: {xs: 'none', lg: 'block'}}} >
                            <SuggestedUsers key={suggestedUpdater} style={{width: '100%'}} suggestionsToShow={3}/>
                        </Box>
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};

class HomePage extends AbstractPage {
    config(): PageConfig {
        return {
            requiredRoles: [],
            requiresAuth: true,
            onlyGuest: false
        };
    }

    renderComponent(): JSX.Element {
        return <PageContent />;
    }
}

export const getStaticProps: GetStaticProps<PageProps> = async() => {
    return {
        props: {
            seo: {
                title: 'Sugarfans',
                description: 'Share moments with your fans. Monetize your content. Make them feel closer than ever.',
                path: ROOT_ROUTES.HOME
            },
            requiresAuth: true
        }
    };
};

export default HomePage;

import { Card, Grid, Box, Theme, ButtonBase } from '@mui/material';
import {makeStyles} from '@theme/makeStyles';
import Link from 'next/link';
import React, { useState } from 'react';
import { ROOT_ROUTES } from '../../Constants/routes';
import UserManager from '../../Provider/Controller/user/UserManager';
import UserAvatar from './UserAvatar';
import UsernameBadge from '../Utils/UsernameBadge';
import Follow from '../Profile/Actions/Follow';
import SocialMedia from '@components/Profile/SocialMedia';
import SVGImage from '@components/Utils/SVGImage';
import SeeMore from '@icons/profile/glyphs-see-more-white.svg';
import ProfileMenu from '@components/Profile/Actions/ProfileMenu';

export const avatarSizeSmall = 72;
export const avatarSizeLarge = 108;

export type UserCardSize = 'small'|'large';

const useStyles = makeStyles<{size: UserCardSize}>()((theme: Theme, {size}) => ({
    card: {
        padding: theme.spacing(1),
        paddingTop: theme.spacing(3),
        overflow: 'visible'
    },
    avatar: {
        objectFit: 'contain',
        marginLeft: 0,
        position: 'absolute',
        left: '10px',
        backgroundColor: theme.palette.background.paper,
        border: '4px solid ' + theme.palette.background.paper,
        width: `${size === 'small' ? avatarSizeSmall : avatarSizeLarge}px`,
        height: `${size === 'small' ? avatarSizeSmall : avatarSizeLarge}px`,
        bottom: `${-(size === 'small' ? avatarSizeSmall : avatarSizeLarge)/2}px`,
    },
    cover: {
        position: 'relative',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        objectFit: 'contain',
        width: '100%',
        height: size === 'small' ? '70px' : '90px',
        borderRadius: '10px'
    },
    pointer: {
        cursor: 'pointer'
    },
    username: {
        marginLeft: `${size === 'small' ? avatarSizeSmall : avatarSizeLarge}px`
    },

    twitterAma: {
        width: '18px !important',
        height: '18px !important'
    },
    socialIcons: {
        '& > .MuiSvgIcon-root': {
            width: 18,
            height: 18,
            cursor: 'pointer'
        }
    },
    iconsContainer: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        marginRight: theme.spacing(1)
    },
    profileButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        marginRight: theme.spacing(1)
    },
}));

interface UserCardProps {
    user: UserManager;
    withFollow?: boolean;
    followSize?: 'large'|'medium'|'small';
    className?: string;
    withSocialMedia?: boolean;
    withMenu?: boolean;
    withoutClick?: boolean;
    size?: UserCardSize;
}

const UserCard = (props: UserCardProps): JSX.Element => {
    const {user, withFollow, followSize, className, withSocialMedia, withoutClick, withMenu, size = 'small'} = props;

    const { classes, cx } = useStyles({size});
    const {username, displayName, avatarUrl, avatarUrlHighRes, headerUrl, influencer} = user instanceof UserManager ? user.data:user;

    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    const ContainerComponent = (containerProps: {children: JSX.Element}): JSX.Element => {
        if (withoutClick) {
            return <Box>{containerProps.children}</Box>;
        }

        return <Link href={ROOT_ROUTES.USER(username)}>{containerProps.children}</Link>;
    };

    return <ContainerComponent>
        <Card className={cx(classes.card, className)} elevation={0}>
            <div
                style={{ backgroundImage: `url(${headerUrl})`}}
                className={cx(classes.cover, !withoutClick && classes.pointer)}
            >
                <UserAvatar
                    avatarUrl={[size === 'large' ? avatarUrlHighRes : avatarUrl]}
                    className={classes.avatar}
                    username={[username]}
                    disableOnClick={withoutClick}
                />

                {withSocialMedia && (
                    <SocialMedia
                        user={user}
                        iconSize={classes.socialIcons}
                        withName={false}
                        withTooltip
                        customTwitter={classes.twitterAma}
                        customAmateur={classes.twitterAma}
                        className={classes.iconsContainer}
                    />
                )}

                {withMenu && (
                    <>
                        <ProfileMenu user={user} open={menuOpen} closeHandler={() => setMenuOpen(false)} />

                        <ButtonBase aria-label='open profile menu' onClick={() => setMenuOpen(true)} className={classes.profileButton}>
                            <SVGImage fill='secondary' width={15} height={15} svg={SeeMore} removeStroke />
                        </ButtonBase>
                    </>
                )}
            </div>

            <Grid container alignItems={'center'} wrap={'nowrap'} mt={1}>
                <Grid item xs={9} container alignItems={'center'} wrap={'nowrap'}>

                    <Box ml={2} position={'relative'}>
                        <UsernameBadge
                            className={classes.username}
                            username={username}
                            displayName={displayName}
                            isInfluencer={influencer}
                            maxUsernameLength={15}
                            disableLink={withoutClick}
                        />
                    </Box>
                </Grid>

                <Grid item xs={3}>
                    {withFollow && <Follow user={user as UserManager} size={followSize} />}
                </Grid>
            </Grid>
        </Card>
    </ContainerComponent>;
};

export default UserCard;

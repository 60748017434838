import React, {useState} from 'react';
import { Theme, Typography } from '@mui/material';
import {makeStyles} from '@theme/makeStyles';
import {useAppContext} from '../../Context/AppContext';
import WarningIcon from '../../icons/glyphs-settings-warning.svg';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import SVGImage from '../Utils/SVGImage';

const useStyles = makeStyles()((theme: Theme) => ({
    emailValid: {
        border: `1px solid ${theme.palette.primary.main}`,
        display: 'flex',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        padding: '10px',
        marginTop: '0.938rem',
        marginBottom: '0.938rem',
        borderRadius: 6
    },
    emailText: {
        margin: 'auto',
        marginLeft: theme.spacing(1)
    },
    resendButton: {
        marginLeft: 'auto',
        textTransform: 'none'
    },
    warning: {
        marginTop: theme.spacing(1.25)
    }
}));

interface EmailValidationBannerProps {
    text?: string;
}

const EmailValidationBanner = (props: EmailValidationBannerProps): JSX.Element => {
    const {text} = props;
    const {classes} = useStyles();
    const {controllers, authUser} = useAppContext();
    const {t} = useTranslation('common');
    const [emailSent, setEmailSent] = useState<boolean>(false);
    
    const handleResend = () => {
        controllers.userController.resendValidationEmail(authUser.data.id)
            .then(() => setEmailSent(true));
    };


    return (
        <div className={classes.emailValid}>
            <SVGImage svg={WarningIcon} width={18} height={18} removeMargin className={!emailSent ? classes.warning : ''} color='text-primary' />

            <Typography variant={'h2'} color={'textPrimary'} className={classes.emailText}>
                <strong>{emailSent ? t('CONFIRMATION_SENT_TO_EMAIL') : (text || t('CONFIRM_EMAIL'))}</strong>
            </Typography>

            {!emailSent && (
                <Button
                    aria-label='resend email verification'
                    className={classes.resendButton}
                    variant="outlined"
                    color="primary"
                    onClick={handleResend}
                >
                    {t('RESEND')}
                </Button>
            )}

        </div>
    );
};

export default EmailValidationBanner;

import { useMediaQuery, useTheme } from '@mui/material';
import { SAFARI_MAC } from '../../Constants';

interface UseSmoothScrollProps {
    timeSmooth: number,
    where: string,
}

const useSmoothScroll = (props: UseSmoothScrollProps): ((el: HTMLElement, scrollPlus?: boolean) => void) => {
    const { timeSmooth, where } = props;
    const theme = useTheme();
    const xlDown = useMediaQuery(theme.breakpoints.down('xl'));

    if (SAFARI_MAC()) {
        const smoothVerticalScrolling = (el: HTMLElement, scrollPlus: boolean) => {
            const eTop = el.getBoundingClientRect().top;
            const eAmt = eTop / 100;
            let curTime = 0;
            while (curTime <= timeSmooth) {
                window.setTimeout(SVS_B, curTime, eAmt, where, scrollPlus);
                curTime += timeSmooth / 100;
            }
        };

        const SVS_B = (eAmt: number, whereSmooth: string, scrollPlus: boolean) => {
            let plus = 0;

            if (SAFARI_MAC() && !xlDown) {
                plus = 0.6;
            } else if (SAFARI_MAC() && xlDown) {
                plus = 1;
            }

            if (where == 'center' || whereSmooth == '') {
                window.scrollBy(0, eAmt / 2);
            }
            if (where == 'top') {
                window.scrollBy(0, scrollPlus ? eAmt + plus : eAmt);
            }
        };

        return smoothVerticalScrolling;
    } 
    
    return el => el.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'end'
    });
    
};

export default useSmoothScroll;
import React from 'react';
import {makeStyles} from '@theme/makeStyles';
import { Fade, IconButton } from '@mui/material';
import useSmoothScroll from '../../Hooks/Binders/useSmoothScroll';
import { Theme } from '@mui/material/styles';
import {useThemeContext} from '@theme/ThemeContext';
import {useAppContext} from '@context/AppContext';
import ArrowUpwardIcon from '@mui/icons-material/KeyboardArrowUp';

const useStyles = makeStyles()((theme: Theme) => ({
    button: {
        backgroundColor: theme.palette.divider,
        position: 'fixed',
        zIndex: 1,
    },
    buttonPositionDesktop: {
        right: theme.spacing(2),
        bottom: theme.spacing(2)
    },
    buttonPositionMobile: {
        right: theme.spacing(1),
        bottom: '3.8rem'
    },
    arrow: {
        color: theme.palette.primary.main
    },
    divRef: {
        position: 'absolute',
        top: 0,
        zIndex: -1,
        visibility: 'hidden'
    }
}));

interface ScrollButtonProps {
    pathname: string;
}

const ScrollButton = (props: ScrollButtonProps): JSX.Element => {
    const { pathname } = props;
    const { classes, cx } = useStyles();
    const { router } = useAppContext();
    const { isDesktop } = useThemeContext();
    const [toggleVisibleButton, setToggleVisibleButton] = React.useState<boolean>(false);

    const scrollRef = React.useRef();

    // Callback de scroll event
    const handleScroll = React.useCallback(() => {
        if (window.scrollY > 300) {
            setToggleVisibleButton(true);
        }

        if (window.scrollY == 0) {
            setToggleVisibleButton(false);
        }
    }, [setToggleVisibleButton]);

    React.useEffect(() => {
        if (router.pathname === pathname) {
            window.addEventListener('scroll', handleScroll, {passive: true});
        }

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [router]);

    const scroll = useSmoothScroll({timeSmooth: 375, where: 'top'});

    return (
        <>
            <div className={classes.divRef} ref={scrollRef} />

            <Fade in={toggleVisibleButton} timeout={500}>
                <IconButton
                    className={cx(
                        classes.button,
                        isDesktop ? classes.buttonPositionDesktop : classes.buttonPositionMobile
                    )}
                    aria-label='back to top'
                    // variant={'contained'}
                    color="primary"
                    disableRipple
                    disableFocusRipple
                    onClick={() => {scroll(scrollRef.current as HTMLElement, false);}}
                >
                    <ArrowUpwardIcon className={classes.arrow} />
                </IconButton>
            </Fade>
        </>
    );
};

export default ScrollButton;
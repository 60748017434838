import UserManager from '../../Provider/Controller/user/UserManager';
import {Box, Grid, IconButton, Typography} from '@mui/material';
import React, {useMemo} from 'react';
import { Theme } from '@mui/material/styles';
import {makeStyles} from '@theme/makeStyles';
import { useUpdater } from '@context/UpdaterContext';
import { useEventBus } from '../../EventBus/EventBus';
import { useTranslation } from 'react-i18next';
import UserCard from './UserCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {A11y, Navigation, Scrollbar} from 'swiper';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {SPACING_FACTOR} from '@theme/theme';
import useHover from '@hooks/Binders/useHover';
import UserCardSkeleton from '@components/User/UserCardSkeleton';
import {useSuggestedUsers, useUserSettings} from '../../Services/SWR';

function* paginate(arr: UserManager[], perPage: number) {
    for (let i = 0; i < arr.length; i+=perPage) {
        yield arr.slice(i, i+perPage);
    }
}

const useStyles = makeStyles()((theme: Theme) => ({
    mb1: {
        marginBottom: theme.spacing(1)
    },
    dot: {
        margin: '0 2px',
        width: 6, height: 6,
        borderRadius: '50%',
        background: theme.palette.primary.contrastText
    },
    activeDot: {
        background: theme.palette.primary.main
    },
    dots: {
        marginTop: theme.spacing(1)
    },
    dismissIconBtn: {
        position: 'absolute',
        top: 0,
        right: 0,
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(4/2), // have theme.spacing(4) vertical space to work with, need to get vertically aligned
        transform: 'translateY(-50%)',
        width: 16, height: 16
    },
    dismissIcon: {
        color: theme.palette.secondary.main,
        width: 16, height: 16,
    },
    userCard: {
        padding: 0
    },
    arrow: {
        color: theme.palette.text.primary,
        transition: 'transform .3s ease'
    },
    scaleUpLeft: {
        transform: 'translateX(-2px) scale(1.1)'
    },
    scaleUpRight: {
        transform: 'translateX(2px) scale(1.1)'
    },
}));

interface SuggestedUsersProps {
    users?: UserManager[],
    suggestionsToShow: number,
    style?: React.CSSProperties
}

const SuggestedUsers = (props: SuggestedUsersProps): JSX.Element => {
    const { classes, cx } = useStyles();
    const { users: initialUsers, suggestionsToShow, style } = props;
    const {t} = useTranslation('common');

    const [users, setUsers] = React.useState(initialUsers);
    const [updated, update] = useUpdater();
    const [,rerender] = useUpdater();
    const [swiper, setSwiper] = React.useState<SwiperCore>(null);

    const {data: settings, loading: settingsLoading} = useUserSettings();
    const genderAsked = useMemo(() => {
        if (settingsLoading) {
            return false;
        }

        if (settings) {
            const asked = settings.find(s => s.name === 'gender_preferences_asked');

            return asked && asked.value === '1';
        }

        return true;
    }, [settings, settingsLoading]);

    const [hoverableLeft, hoveringLeft] = useHover();
    const [hoverableRight, hoveringRight] = useHover();

    useEventBus([{
        events: ['sugarfans.user.followed', 'sugarfans.user.unfollowed'],
        id: 'suggestedUsers-follow-update',
        callback: () => {update();}
    }]);

    const {data, loading} = useSuggestedUsers(!initialUsers && genderAsked);

    // Controlled listener
    React.useEffect(() => {
        if (initialUsers) {
            setUsers(initialUsers);
        }
    }, [initialUsers, updated]);

    // Uncontrolled listener
    React.useEffect(() => {
        if (!initialUsers) {
            if (data && !loading) {
                setUsers(data);
            }
        }
    }, [loading, data]);

    const dismissUser = (user: UserManager) => () => {
        const oldUsers = users;
        setUsers(prev => prev.filter(u => u.data.id !== user.data.id));
        user.dismiss().catch(() => {
            // We set a copy here to maintain the previous order
            setUsers(oldUsers);
        });
    };

    SwiperCore.use([A11y, Navigation, Scrollbar]);

    // Makeshift pagination
    const perPage = suggestionsToShow;
    // const total = users && users.length || 0;
    
    const paginatedData = React.useMemo(() => ((users && Array.from(paginate(users, perPage)) || [])), [users]);
    
    const slices = React.useMemo(() => paginatedData.map(slice => slice.length !== 0 && slice.map(user => (
        <Box key={user.data.id} position='relative' mb={'27px'}>
            <UserCard user={user} withFollow followSize={'small'} className={classes.userCard} />
            <IconButton
                onClick={dismissUser(user)}
                className={classes.dismissIconBtn}
                aria-label='dismiss suggested user'
                size="large">
                <CloseRoundedIcon className={classes.dismissIcon} />
            </IconButton>
        </Box>
    ))), [paginatedData]);

    return (
        <Box style={style}>
            <Grid container alignItems='center' justifyContent='space-between' className={classes.mb1}>
                <Typography variant='h1' fontWeight={700} fontSize={'24px'}>{t('SUGGESTIONS')}</Typography>

                {swiper && <Box mr={'-12px'}>
                    <IconButton
                        aria-label='previous suggested user page'
                        disabled={swiper.isBeginning}
                        onClick={() => swiper.slidePrev()}
                        size="large"
                        className={cx(classes.arrow, hoveringLeft && classes.scaleUpLeft)}
                        disableRipple
                        {...hoverableLeft}
                    >
                        <ChevronLeftRoundedIcon/>
                    </IconButton>
                    <IconButton
                        aria-label='next suggested user page'
                        disabled={swiper.isEnd}
                        onClick={() => swiper.slideNext()}
                        size="large"
                        className={cx(classes.arrow, hoveringRight && classes.scaleUpRight)}
                        disableRipple
                        {...hoverableRight}
                    >
                        <ChevronRightRoundedIcon/>
                    </IconButton>
                </Box>}
            </Grid>

            {!users && (
                Array(suggestionsToShow).fill(0).map((value, index) => (
                    <UserCardSkeleton mb={1} key={index} />
                ))
            )}

            {users && users.length > 0 && <Swiper threshold={10} onSwiper={setSwiper} onRealIndexChange={() => rerender()} spaceBetween={SPACING_FACTOR} slidesPerView='auto' watchSlidesProgress watchSlidesVisibility >
                {paginatedData.map((slice, idx) => {
                    if (slice.length === 0) {
                        return null;
                    }

                    return (
                        <SwiperSlide key={slice[0].data.id}>
                            <Grid container direction='column'>
                                {slices[idx]}
                            </Grid>
                        </SwiperSlide>
                    );  
                })}
            </Swiper>}
            
            {users && users.length === 0 && <Typography variant={'h3'} color={'textPrimary'} fontSize={'16px'}>
                {t('THERE_ARE_NO_SUGGESTED_USERS')}
            </Typography>}
        </Box>
    );
};

export default SuggestedUsers;